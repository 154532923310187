.carousel {
    position: relative;
  }
  
  .carousel-item {
    position: absolute;
    visibility: hidden;
  }
  
  .carousel-item.visible {
    visibility: visible;
  }