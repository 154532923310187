.AboutUsContentMain
{
    margin: 30px;
    width: 90%;
}

.AboutUsContentSub
{
    margin: 25px; 
    padding: 20px;
    background-color: rgb(248, 250, 252);
    font-size: larger;
    border-radius :15px;
    box-shadow: 1px 1px 1px 1px #888888;
}

.AboutUsContentSub:hover{
    background-color: antiquewhite;
    box-shadow: 5px 5px 5px 5px #888888;
    border-radius :5px;
}