.HomeAboutOverview {
  position: relative;
  padding: 150px 10px;
  background-color: #f6f6f6;
}

.HomeAboutOverview__text {
  position: relative;
  text-align: center;
  padding: 0px 50px;
}
.HomeAboutOverview__text h2 {
  margin-bottom: 40px;
}

.HomeAboutOverview__logo {
  width: 113px;
  height: 70px;
  margin: 0px auto 35px auto;
  filter: drop-shadow(5px 5px 2px rgba(0,0,0,0.15));
  background-image: url("./logo_full_dark_750x200.png");
  background-size: auto 100px;
  background-position: left top;
  background-repeat: no-repeat;
}
