.FooterMain {
  display: flex;
  justify-content: center;
}

.FooterMain__holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  
  gap: 25px;
}

.FooterMain__logo {
  position: relative;
  width: 300px;
  height: 80px;
  margin: 0px auto;
  
}

.FooterMain__logo__image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../logos/logo_full_dark_750x200.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.FooterMain__socialmedia {
  /* margin: 25px 0px 0px 70px; */
  
  display: flex;
  justify-content: center;
}

.FooterMain__copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-dark);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  text-align: center;
  opacity: 0.5;
  
}

.FooterMain__copyright__sign {
  font-size: 24px;
  margin-right: 7px;
}

