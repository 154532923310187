.sermon_parent_div{
display: flex;
flex-wrap: wrap;
max-width: 100vw;
}
.sermon_item_div {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    padding: 20px;
    background-color: rgba(252, 77, 77, 0.411);
    max-width: 100vw;
    justify-items: center;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 2px grey;
    /* width: 96%;
    min-width: 450px;
    max-width: 560px; */
}

.youtube_frame {
    margin: 10px 0px;
    min-width: 450px;
    min-height: 315px;
    max-width: 560px;
    max-height: 315px;
}

.description
{
    max-width: 500px; 
}

