.parentDiv {
    background-color: rgb(233, 232, 232);
}

.heading {
    padding: 20px;
    text-align: center;
}

.flex-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(255, 253, 253);
    justify-items: center;
    justify-content: space-evenly;
    padding: 40px;
    gap:20px;
}

.flex-item1 {
    padding: 20px;
    margin: 5px;
    background-color: rgb(245, 242, 239);
    /* background-image: url('./images/worship.jpg') ;
    background-size: cover; */
    opacity: 1;
    box-shadow: 1px 1px 1px 1px #888888;
    width: 300px;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    border-top-left-radius :30px;
    border-bottom-right-radius :30px;
}

.flex-item2 {
    padding: 20px;
    margin: 5px;
    background-color: rgb(245, 242, 239);
    /* background-image: url('./images/sundayschool.jpg') ;
    background-size: cover; */
    box-shadow: 1px 1px 1px 1px #888888;
    width: 300px;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    border-top-left-radius :30px;
    border-bottom-right-radius :30px;
}


.flex-item3 {
    padding: 10px;
    margin: 5px;
    background-color: rgb(245, 242, 239);
    /* background-image: url('./images/youth.jpg') ;
    background-size: cover; */
    box-shadow: 1px 1px 1px 1px #888888;
    width: 300px;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    border-top-left-radius :30px;
    border-bottom-right-radius :30px;
}

.flex-item1:hover,.flex-item2:hover,.flex-item3:hover{
    /* animation: animation2 0.5s ; */
    width: 305px;height: 205px;background-color:#ADD8E6;border-bottom: solid black 2px;
    transition: box-shadow 0.25s ease-in-out 0s;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

@keyframes animation1 {
    0%{opacity: 1;}
    50%{opacity: 0.8;}
    100%{opacity: 1;}
    /* from {transform: rotate(0deg);padding: 20px;}
    to {transform: rotate(.9deg);padding: 15px;} */
  }

  @keyframes animation2 {
    0%{width: 300px;height: 200px;background-color: rgb(245, 242, 239);}
    50%{width: 305px;height: 205px;background-color: rgb(226, 204, 181);border-bottom: solid black 2px;}
    100%{width: 300px;height: 200px;background-color: rgb(245, 242, 239);}
    /* from {transform: rotate(0deg);padding: 20px;}
    to {transform: rotate(.9deg);padding: 15px;} */
  }
  @keyframes animation3 {
    0%{width: 300px;height: 200px;background-color: rgb(245, 242, 239);}
    50%{width: 305px;height: 205px;background-color: rgb(226, 204, 181);border-bottom: solid black 2px;}
    100%{width: 300px;height: 200px;background-color: rgb(245, 242, 239);}
    /* from {transform: rotate(0deg);padding: 20px;}
    to {transform: rotate(.9deg);padding: 15px;} */
  }