.HomeGreetings {
  padding: 100px 0px 200px 0px;
  background-color: #fff;
}

.HomeGreetings__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px 25px;
}

.HomeGreetings__pastor {
  position: relative;
  width: 100%;
  max-width: 400px;
  flex-basis: 400px;
  border-radius: 5px;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.HomeGreetings__pastor__image {
  position: relative;
  /* filter: saturate(0.85) contrast(1.15) brightness(1.25); */
  filter: saturate(1) contrast(1) brightness(1);
}
.HomeGreetings__pastor__image img {
  width: 100%;
}

.HomeGreetings__pastor__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 30%;
  color: #fff;
  font-family: "Roboto", sans-serif;
  text-align: center;
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 3%, rgba(0,0,0,0.8) 31%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.08) 3%,rgba(0,0,0,0.8) 31%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.08) 3%,rgba(0,0,0,0.8) 31%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
}
.HomeGreetings__pastor__info__name {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 7px;
}
.HomeGreetings__pastor__info__role {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.HomeGreetings__text {
  justify-content: center;
  max-width: 700px;
  margin-top: 40px;
  padding: 5px 0px 0px 5px;
}
.HomeGreetings__text h2 {
  margin-bottom: 40px;
}

.HomeGreetings__signature {
  width: 300px;
  height: 75px;
  margin-top: 50px;
  background-image: url("./images/pastor-signature.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
